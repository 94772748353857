<script setup lang="ts">
  import { onBeforeMount, onBeforeUnmount, ref } from 'vue'
  import { useI18n } from 'vue-i18n'
  import { useRoute, useRouter } from 'vue-router'
  import { VueQueryDevtools } from '@tanstack/vue-query-devtools'
  import { storeToRefs } from 'pinia'

  import { setCallback } from '@algorh/shared'

  import { useAppStore } from '@/appStore'
  import { useAlertsStore } from '@/composables/useAlertsStore'

  import Alerts from './components/Alerts.vue'
  import AppUpdateModal from './components/modals/alerts/AppUpdateModal.vue'
  import ExpiredSessionModal from './components/modals/alerts/ExpiredSessionModal.vue'
  import ForbiddenModal from './components/modals/alerts/ForbiddenModal.vue'
  import InternalErrorModal from './components/modals/alerts/InternalErrorModal.vue'
  import { useAppInit } from './composables/useAppInit'
  import { useConfigs } from './composables/useConfigs'
  import { i18n, loadI18nOverrides } from './plugins/i18n'

  // Composables
  const { t, locale } = useI18n()
  const { isAuthenticated } = useAppInit()

  useConfigs()

  const router = useRouter()
  const route = useRoute()

  const alertsStore = useAlertsStore()

  // Data
  const appStore = useAppStore()

  const { appTenant } = storeToRefs(appStore)

  const { setAlert, removeAlert } = alertsStore

  const { alerts } = storeToRefs(alertsStore)

  // Refs
  const errorModals = ref({
    update: false,
    expired: false,
    forbidden: false,
    internal: false,
  })

  // Methods
  function openErrorModal(key: string) {
    errorModals.value = {
      update: false,
      expired: false,
      forbidden: false,
      internal: false,
      [key]: true,
    }
  }

  function closeErrorModal(key: string) {
    errorModals.value = {
      update: false,
      expired: false,
      forbidden: false,
      internal: false,
      [key]: false,
    }
  }

  function handleErrors(status: number) {
    switch (status) {
    case 401: // Unauthenticated
    case 419: // CSRF Token Mismatch
      if (isAuthenticated.value) {
        openErrorModal('expired')
      }
      break
    case 403: // Forbidden
      openErrorModal('forbidden')
      break
    case 500: // Internal Server Error
    case 502: // Bad Gateway
    case 503: // Service Unavailable
    case 504: // Gateway Timeout
      openErrorModal('internal')
      break
    }
  }

  function handleOnline() {
    removeAlert('network')
  }

  function handleOffline() {
    setAlert(
      {
        variant: 'danger',
        title: t('errors.Lost connection'),
        message: t('errors.Please check your Internet connection'),
        autoHide: false,
        closable: false,
      },
      'network',
    )
  }

  onBeforeMount(async () => {
    // Load i18n overrides
    await loadI18nOverrides(i18n, locale.value, appTenant.value)

    setCallback(handleErrors)

    router.onError((error) => {
      if (error.message?.includes('Failed to fetch dynamically imported module')) {
        openErrorModal('update')
      }
    })

    window.addEventListener('online', handleOnline)
    window.addEventListener('offline', handleOffline)
  })

  onBeforeUnmount(() => {
    window.removeEventListener('online', handleOnline)
    window.removeEventListener('offline', handleOffline)
  })
</script>

<template>
  <div
    id="content"
  >
    <Component
      :is="route.meta.layout || 'div'"
      class="layout"
    >
      <RouterView />
    </Component>
  </div>

  <div id="alerts">
    <Alerts
      v-for="alert in alerts"
      v-bind="alert"
      :key="alert.id"
    />
  </div>

  <div
    id="modals"
    class="modals"
  />

  <AppUpdateModal v-if="errorModals.update" />

  <ExpiredSessionModal
    v-if="errorModals.expired"
    @close="closeErrorModal('expired')"
  />

  <ForbiddenModal
    v-if="errorModals.forbidden"
    @close="closeErrorModal('forbidden')"
  />

  <InternalErrorModal
    v-if="errorModals.internal"
    @close="closeErrorModal('internal')"
  />

  <VueQueryDevtools :button-position="'bottom-left'" />
</template>

<style scoped>
  #content {
    display: flex;
    width: 100vw;
    height: 100vh;
    flex: 1 1 auto;
    flex-direction: column;

    .layout {
      display: flex;
      flex: 1 1 auto;
    }
  }

  #alerts {
    position: absolute;
    z-index: 90;
    right: var(--alg-spacing-m);
    bottom: var(--alg-spacing-m);
    left: var(--alg-spacing-m);
    display: flex;
    overflow: auto;
    width: 400px;
    max-height: 80vh;
    flex-direction: column;
    align-items: center;
    padding: var(--alg-spacing-xl);
    margin: auto;
    gap: var(--alg-spacing-m);
    pointer-events: none;
  }
</style>
