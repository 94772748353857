<script setup lang="ts">
  import { useRouter } from 'vue-router'
  import { storeToRefs } from 'pinia'

  import { AlgIllustration } from '@algorh/ui'

  import { useAppStore } from '@/appStore'

  // Composables

  const router = useRouter()

  const appStore = useAppStore()

  // Data
  const { appLogo, appName } = storeToRefs(appStore)

  // Methods
  async function handleLogoClick() {
    await router.push('/')
  }
</script>

<template>
  <div
    class="layout-logo"
  >
    <button
      type="button"
      class="logotype"
      @click="handleLogoClick"
    >
      <AlgIllustration
        class="logo"
        prefix="brand"
        :name="appLogo"
        :size="32"
      />
      <span class="title">
        {{ appName }}
      </span>
    </button>
  </div>
</template>

<style scoped>
  .layout-logo {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .logotype {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: auto;

      .logo {
        width: 40px;
        flex: 1 1 auto;
      }

      .title {
        flex: 1 1 auto;
        margin-left: var(--alg-spacing-s);
        color: var(--alg-color-text-primary);
        font-size: var(--alg-font-size-m);
        font-weight: var(--alg-font-weight-bold);
        text-align: left;
        white-space: nowrap;
      }
    }
  }
</style>
