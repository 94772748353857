import { RouteRecordRaw, RouterView } from 'vue-router'

import { UserPermissions } from '@/core/enums'
import AppLayout from '@/layouts/app/App.vue'

const settingsRouter: RouteRecordRaw[] = [
  // Projects
  {
    name: 'projects',
    path: 'projects',
    redirect: { name: 'project-list' },
    meta: {
      permissions: [UserPermissions.MANAGE_PROJECTS],
      layout: AppLayout,
      breadcrumb: 'projects.Projects',
    },
    component: RouterView,
    children: [
      {
        name: 'project-list',
        path: '',
        component: () => import('./pages/Projects/Projects.vue'),
      },
      {
        name: 'project',
        path: ':projectId',
        redirect: { name: 'project-activities' },
        meta: {
          permissions: [UserPermissions.MANAGE_PROJECTS],
          layout: AppLayout,
          breadcrumb: ':projectId',
        },
        component: () => import('./pages/Projects/Project.vue'),
        children: [
          {
            name: 'project-activities',
            path: 'activities',
            meta: {
              permissions: [UserPermissions.MANAGE_PROJECTS],
              breadcrumb: 'activities.Activities',
            },
            component: () => import('./pages/Projects/Activities/ProjectActivities.vue'),
          },
          {
            name: 'project-rules',
            path: 'rules',
            meta: {
              breadcrumb: 'rules.Collective rules',
            },
            component: () => import('./pages/Projects/RulesAmbitions/ProjectRules.vue'),
          },
          {
            name: 'project-ambitions',
            path: 'ambitions',
            meta: {
              breadcrumb: 'ambitions.Ambitions',
            },
            component: () => import('./pages/Projects/RulesAmbitions/ProjectAmbitions.vue'),
          },
        ],
      },
    ],
  },
  {
    path: 'settings',
    name: 'settings',
    meta: {
      breadcrumb: 'common.Settings',
      breadcrumbInactive: true,
    },
    children: [
      // Hyper periods
      {
        path: 'hyper-periods',
        name: 'settings-hyper-periods',
        component: () => import('./pages/HyperPeriods.vue'),
        meta: {
          layout: AppLayout,
          permissions: [UserPermissions.ACCESS_HYPER_PERIODS],
          breadcrumb: 'hyperPeriods.Hyper periods',
        },
        children: [
          {
            path: ':id/:stepId?',
            name: 'settings-hyper-period',
            component: () =>
              import('./pages/HyperPeriodsStepper.vue'),
          },
        ],
      },
      // Activities
      {
        path: 'activities',
        name: 'settings-activities',
        component: () => import('./pages/Activities.vue'),
        meta: {
          layout: AppLayout,
          permissions: [UserPermissions.MANAGE_ACTIVITIES],
          breadcrumb: 'activities.Activities',
        },
      },
      // Rules and ambitions
      {
        path: 'rules-ambitions',
        name: 'settings-rules-ambitions',
        redirect: { name: 'settings-rules' },
        component: () => import('./pages/RulesAmbitions/RulesAmbitions.vue'),
        meta: {
          layout: AppLayout,
          permissions: [UserPermissions.MANAGE_RULES_AND_AMBITIONS],
          breadcrumb: 'rules.Rules and Ambitions',
          breadcrumbInactive: true,
        },
        children: [
          {
            path: 'rules',
            name: 'settings-rules',
            component: () => import('./pages/RulesAmbitions/Rules/GlobalRules.vue'),
            meta: {
              breadcrumb: 'rules.Rules',
            },
          },
          {
            path: 'ambitions',
            name: 'settings-ambitions',
            component: () => import('./pages/RulesAmbitions/Ambitions/GlobalAmbitions.vue'),
            meta: {
              breadcrumb: 'ambitions.Ambitions',
            },
          },
        ],
      },
      // Tags
      {
        path: 'tags',
        name: 'settings-tags',
        component: () => import('./pages/Tags.vue'),
        meta: {
          layout: AppLayout,
          permissions: [UserPermissions.ACCESS_TAGS],
          breadcrumb: 'tags.Tags',
        },
      },
      // Users
      {
        path: 'users',
        name: 'settings-users',
        redirect: { name: 'settings-users-list' },
        component: RouterView,
        meta: {
          layout: AppLayout,
          permissions: [UserPermissions.MANAGE_USERS],
          breadcrumb: 'users.User accounts',
        },
        children: [
          {
            path: '',
            name: 'settings-users-list',
            component: () => import('./pages/Users/Users.vue'),
          },
          {
            path: 'create',
            name: 'settings-users-create',
            component: () => import('./pages/Users/CreateUser.vue'),
          },
          {
            path: ':id',
            name: 'settings-user',
            component: RouterView,
            redirect: { name: 'settings-users-update' },
            meta: {
              breadcrumb: ':id',
            },
            children: [
              {
                path: '',
                name: 'settings-users-update',
                component: () => import('./pages/Users/EditUser.vue'),
              },
              {
                path: 'work-cycles',
                name: 'settings-users-work-cycles',
                component: RouterView,
                meta: {
                  breadcrumb: 'workCycles.Work cycles',
                  breadcrumbInactive: true,
                },
                children: [
                  {
                    path: 'create',
                    name: 'settings-users-work-cycles-create',
                    component: () =>
                      import('./pages/Users/PersonalWorkCycleForm.vue'),
                    meta: {
                      breadcrumb: 'workCycles.Add work cycle',
                    },
                  },
                  {
                    path: ':workCycleId',
                    name: 'settings-users-work-cycles-update',
                    component: () =>
                      import('./pages/Users/PersonalWorkCycleForm.vue'),
                    meta: {
                      breadcrumb: ':workCycleId',
                    },
                  },
                  { // @TODO a supprimer car gérer dans les droits pour la lecture seule
                    path: ':workCycleId/show',
                    name: 'settings-users-work-cycles-show',
                    component: () =>
                      import('./pages/Users/PersonalWorkCycleForm.vue'),
                    meta: {
                      breadcrumb: ':workCycleId',
                    },
                  },
                ],
              },
            ],
          },

        ],
      },
      // Work cycles
      {
        path: 'work-cycles',
        name: 'settings-work-cycles',
        redirect: { name: 'settings-work-cycles-list' },
        component: RouterView,
        meta: {
          layout: AppLayout,
          permissions: [UserPermissions.ACCESS_WORK_CYCLES],
          breadcrumb: 'workCycles.Work cycles',
        },
        children: [
          {
            path: '',
            name: 'settings-work-cycles-list',
            component: () => import('./pages/WorkCycles/WorkCycles.vue'),
          },
          {
            path: 'create',
            name: 'settings-work-cycles-create',
            component: () => import('./pages/WorkCycles/WorkCycleForm.vue'),
          },
          {
            path: 'update/:id',
            name: 'settings-work-cycles-update',
            component: () => import('./pages/WorkCycles/WorkCycleForm.vue'),
          },
          {
            path: 'show/:id',
            name: 'settings-work-cycles-show',
            component: () => import('./pages/WorkCycles/WorkCycleForm.vue'),
          },
        ],
      },
      // Subdivisions
      {
        path: 'subdivisions',
        name: 'settings-subdivisions',
        component: () => import('./pages/Subdivisions/Subdivisions.vue'),
        meta: {
          layout: AppLayout,
          permissions: [UserPermissions.MANAGE_SUBDIVISIONS],
          breadcrumb: 'subdivisions.Subdivisions',
        },
        children: [
          {
            path: ':subdivisionId',
            name: 'settings-subdivisions-subdivision',
            component: () =>
              import('./pages/Subdivisions/CreateOrUpdateSubdivisionModal.vue'),
          },
          {
            path: ':parentSubdivisionId/create',
            name: 'settings-subdivisions-subdivision-creation',
            component: () =>
              import('./pages/Subdivisions/CreateOrUpdateSubdivisionModal.vue'),
          },
        ],
      },
      // Roles and permissions
      {
        path: 'roles-permissions',
        name: 'settings-roles-permissions',
        component: () => import('./pages/RolesPermissions.vue'),
        meta: {
          layout: AppLayout,
          permissions: [UserPermissions.MANAGE_ROLES],
          breadcrumb: 'rolesPermissions.Roles and permissions',
        },
      },
    ],
  },
]

export { settingsRouter }
