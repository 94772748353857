export const supportRouter = [
  {
    path: 'support',
    name: 'support',
    component: () => import('./pages/Support.vue'),
    meta: {
      breadcrumb: 'support.Online support',
    },
  },
]
