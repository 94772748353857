import { DateString, fetchJson } from '@algorh/shared'

import { Calendar, ScheduleSatisfaction, ScheduleStatistics } from '../types/Schedule'

import { GetScheduleSatisfactionsDto, GetSchedulesDto } from './customerAdvisers.service.type'

const BASE_URI = '/api/calendar/customer-advisers'

const CalendarCustomerAdvisersApiService = {
  getSchedules(params: GetSchedulesDto) {
    return fetchJson<ScheduleStatistics[], null, GetSchedulesDto>(
      'GET', `${BASE_URI}/me/schedules`, null, params,
    )
  },
  getScheduleByDate(date: DateString) {
    return fetchJson<Calendar, null, { date: DateString }>(
      'GET', `${BASE_URI}/me/schedules/at`, null, { date },
    )
  },
  getGlobalSatisfaction() {
    return fetchJson<{ satisfied_slots: number, not_satisfied_slots: number }>(
      'GET', `${BASE_URI}/me/statistics`,
    )
  },
  getScheduleSatisfactions(dto: GetScheduleSatisfactionsDto) {
    return fetchJson<ScheduleSatisfaction[], null, GetScheduleSatisfactionsDto>(
      'GET', `${BASE_URI}/me/schedules/satisfactions`, null, dto,
    )
  },
}

export { CalendarCustomerAdvisersApiService }
