<script setup lang="ts">
  import { useI18n } from 'vue-i18n'

  import { AlgIllustration, AlgModal } from '@algorh/ui'

  const { t } = useI18n()

  const handleConfirm = () => {
    localStorage.clear()
    window.location.reload()
  }
</script>

<template>
  <AlgModal
    name="app-update"
    size="m"
    :allow-close="false"
    :allow-cancel="false"
    :confirm-button-text="t('common.Continue')"
    @confirm="handleConfirm"
  >
    <template #title>
      {{ t('common.New version available') }}
    </template>
    <template #content>
      <div class="content-wrapper">
        <AlgIllustration
          name="deploying"
          :size="148"
        />
        <p class="subtitle">
          {{ t('common.A new version of the application is available') }}
        </p>
        <p>{{ t('common.Please update to continue using the application') }}</p>
      </div>
    </template>
  </AlgModal>
</template>

<style scoped>
.content-wrapper {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--alg-spacing-m);

  p {
    line-height: var(--alg-font-line-height);
    text-align: center;

    &.subtitle {
      font-weight: var(--alg-font-weight-bold);
    }
  }
}
</style>
