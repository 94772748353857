<script lang="ts" setup>
  import { ref } from 'vue'
  import { useI18n } from 'vue-i18n'

  import { FetchError, Nullable } from '@algorh/shared'
  import { AlgButton, AlgPasswordInput } from '@algorh/ui'

  import { MeApiService } from '@/core/services'

  // Composables
  const { t } = useI18n()

  // Refs
  const currentPassword = ref<Nullable<string>>(null)
  const newPassword = ref<Nullable<string>>(null)
  const newPasswordConfirmation = ref<Nullable<string>>(null)

  const isSuccess = ref(false)

  const errors = ref<Record<string, string[]>>({})

  // Methods
  async function handleChangePassword() {
    if (!currentPassword.value || !newPassword.value || !newPasswordConfirmation.value) {
      return
    }

    try {
      await MeApiService.putPassword({
        previous_password: currentPassword.value,
        password: newPassword.value,
        password_confirmation: newPasswordConfirmation.value,
      })

      currentPassword.value = null
      newPassword.value = null
      newPasswordConfirmation.value = null

      errors.value = {}

      isSuccess.value = true
    } catch (e) {
      errors.value = (e as FetchError).errors

      isSuccess.value = false
    }
  }
</script>

<template>
  <div class="account-section">
    <h1 class="title">
      {{ t('account.My security') }}
    </h1>
    <div class="fields-wrapper">
      <AlgPasswordInput
        id="account-current-password"
        v-model="currentPassword"
        class="field"
        variant="secondary"
        size="l"
        :label="t('account.Current password')"
        :errors="errors.previous_password"
        required
      />
      <AlgPasswordInput
        id="account-new-password"
        v-model="newPassword"
        class="field"
        variant="secondary"
        size="l"
        :label="t('account.New password')"
        :errors="errors.password"
        required
      />
      <AlgPasswordInput
        id="account-new-password-confirm"
        v-model="newPasswordConfirmation"
        class="field"
        variant="secondary"
        size="l"
        :label="t('account.New password confirmation')"
        required
      />
      <p
        v-if="isSuccess"
        class="success-message"
      >
        {{ t('account.Your password has been changed successfully') }}
      </p>
    </div>
    <AlgButton
      :label="t('account.Change password')"
      @click="handleChangePassword"
    />
  </div>
</template>

<style src="./index.css" />

<style scoped>
  .success-message {
    color: var(--alg-color-state-success);
    font-size: var(--alg-font-size-m);
  }

  .fields-wrapper {
    margin-bottom: var(--alg-spacing-l);
  }
</style>
