<script setup lang="ts">
  import { useI18n } from 'vue-i18n'

  import { AlgIllustration, AlgModal } from '@algorh/ui'

  const emit = defineEmits<{
    (e: 'close'): void
  }>()

  const { t } = useI18n()

  const handleClose = () => {
    emit('close')
  }
</script>

<template>
  <AlgModal
    name="forbidden"
    size="m"
    :allow-cancel="false"
    :confirm-button-text="t('common.Continue')"
    @close="handleClose"
    @confirm="handleClose"
  >
    <template #title>
      {{ t('errors.Unauthorized action') }}
    </template>
    <template #content>
      <div class="content-wrapper">
        <AlgIllustration
          name="forbidden"
          :size="148"
        />
        <p class="subtitle">
          {{ t('errors.You do not have the permission to perform this action') }}
        </p>
        <p>{{ t('errors.If the problem persists, please contact your administrator') }}</p>
      </div>
    </template>
  </AlgModal>
</template>

<style scoped>
.content-wrapper {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--alg-spacing-m);

  p {
    line-height: var(--alg-font-line-height);
    text-align: center;

    &.subtitle {
      font-weight: var(--alg-font-weight-bold);
    }
  }
}
</style>
