import { ref } from 'vue'
import { defineStore } from 'pinia'

import { Notification } from '@/core/types/Me'

export const useNotificationsStore = defineStore('notifications', () => {
  const activityChangeNotifications = ref<Notification[]>([])
  const displayNotificationsWarning = ref<boolean>(true)

  return {
    activityChangeNotifications,
    displayNotificationsWarning,
  }
})
