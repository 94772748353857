import { RouteLocation, RouterView } from 'vue-router'

import { dayjs, DTF } from '@algorh/shared'

import { UserPermissions } from '@/core/enums'
import { App as AppLayout } from '@/layouts/app'

const usersRouter = [
  {
    path: 'users',
    name: 'users',
    redirect: { name: 'user-list' },
    component: RouterView,
    meta: {
      layout: AppLayout,
      breadcrumb: 'users.Users',
    },
    children: [
      {
        path: '',
        name: 'user-list',
        redirect: { name: 'users-customer-advisers-list' },
        component: () => import('./pages/Users.vue'),
        children: [
          {
            path: 'customer-advisers',
            redirect: { name: 'users-customer-advisers' },
            name: 'users-customer-advisers-list',
            component: RouterView,
            meta: {
              permissions: [UserPermissions.ACCESS_CUSTOMER_ADVISERS],
              breadcrumb: 'users.Advisers',
            },
            children: [
              {
                path: '',
                name: 'users-customer-advisers',
                component: () => import('./pages/UsersCustomerAdvisers.vue'),
              },
              {
                path: ':id',
                name: 'users-customer-adviser',
                meta: {
                  breadcrumb: ':id',
                },
                redirect: (to: RouteLocation) => ({
                  name: 'users-customer-adviser-details',
                  params: {
                    id: to.params.id,
                    // Calendrier s'ouvre tjs sur le lundi de la semaine en cours
                    date: dayjs().startOf('week').format(DTF.DATE),
                  },
                }),
                children: [
                  {
                    path: ':date',
                    name: 'users-customer-adviser-details',
                    component: () => import('./pages/user-details/CustomerAdviserDetails.vue'),
                  },
                  {
                    path: ':date/edit',
                    name: 'users-customer-adviser-edit',
                    component: () => import('./pages/user-details/CustomerAdviserPlanningEdition.vue'),
                    meta: {
                      breadcrumb: 'scheduling.Schedule manually',
                    },
                  },
                ],
              },
            ],
          },
          {
            path: 'supervisors',
            name: 'users-supervisors',
            component: () => import('./pages/UsersSupervisors.vue'),
            meta: {
              permissions: [UserPermissions.ACCESS_OTHER_USERS],
              breadcrumb: 'users.Supervisors',
            },
          },
          {
            path: 'trainers',
            redirect: { name: 'users-trainers' },
            name: 'users-trainers-list',
            component: RouterView,
            meta: {
              permissions: [UserPermissions.ACCESS_TRAINERS],
              breadcrumb: 'users.Trainers',
            },
            children: [
              {
                path: '',
                name: 'users-trainers',
                component: () => import('./pages/UsersTrainers.vue'),
              },
              {
                path: ':id',
                name: 'users-trainer',
                meta: {
                  breadcrumb: ':id',
                },
                redirect: (to: RouteLocation) => ({
                  name: 'users-trainer-details',
                  params: {
                    id: to.params.id,
                    // Calendrier s'ouvre tjs sur le lundi de la semaine en cours
                    date: dayjs().startOf('week').format(DTF.DATE),
                  },
                }),
                children: [
                  {
                    path: ':date',
                    name: 'users-trainer-details',
                    component: () => import('./pages/user-details/TrainerDetails.vue'),
                  },
                  {
                    path: ':date/edit',
                    name: 'users-trainer-edit',
                    component: () => import('./pages/user-details/TrainerPlanningEdition.vue'),
                    meta: {
                      breadcrumb: 'scheduling.Schedule manually',
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
]

export { usersRouter }
